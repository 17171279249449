import streamingStyle from "./Streaming.module.css";

import logo from "../assets/images/logo.svg"
import qeaIcon from "../assets/images/qea-icon.svg";
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
    styled,
    TextField,
    Typography, useMediaQuery,
    useTheme
} from "@mui/material";
import {useEffect, useState} from "react";

import {API_URL, TOOLPRESS_API} from "../config";
import axios from "axios";
import {useParams} from "react-router-dom";
import useAxios from "axios-hooks";

const CustomTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        color: '#0f2e5d',
        fontFamily: 'futura-pt, sans-serif',
        '& fieldset': {
            borderColor: '#0f2e5d',
        },
        '&:hover fieldset': {
            borderColor: '#0f2e5d',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#0f2e5d',
        },
    },
    '& .MuiInputLabel-root': {
        color: '#0f2e5d',
        fontFamily: 'futura-pt, sans-serif',
    },
});


function Streaming() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [question, setQuestion] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [sending, setSending] = useState(false);
    const [success, setSuccess] = useState(false);

    const [access, setAccess] = useState(false);
    const [alternativePlayer, setAlternativePlayer] = useState(false);
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        nome: '',
        cognome: '',
        email: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Dati inviati:', formData);
        setLoading(true);
        try {
            const response = await axios.post(API_URL, formData);
            setAccess(true);
            setLoading(false)
        } catch (error) {
            console.error('Errore durante l\'invio dei dati:', error);
            setLoading(false)
        }
    };

    const handleChangeValue = (e) => {
        setQuestion(e.target.value);
    }

    const postQuestions = async () => {
        setSending(true);
        await axios.post(`${TOOLPRESS_API}/question/erRT1vUOrpYxtDqDo3Wb`, {question: {domanda: question, Email: formData.email, Nome: formData.nome, Cognome:formData.cognome }});
        setSending(false);
        setQuestion('');
        setSuccess(true);

        setTimeout(() => {
            setSuccess(false);
        }, 3000);
    }

    const switchPlayer = () => {
        setAlternativePlayer(!alternativePlayer);
    }

    const isFormComplete = formData.nome && formData.cognome && formData.email;

    return(
        <div className={streamingStyle.mainContainer}>
            {success &&
                <div className={streamingStyle.alertContainer}>
                    <Alert severity="success">Domanda inviata con successo.</Alert>
                </div>
            }
            <img src={logo} alt={''} className={streamingStyle.logoSmall}/>
            <hr className={streamingStyle.hrLine}/>
            {!access &&
                <>
                    <img src={'/images/header.png'} alt={''} className={streamingStyle.logo}/>
                    <Container maxWidth="md" sx={{margin: 'auto', width: isMobile ? '100%' : '30%'}}>
                        <Box sx={{padding: '5%', borderRadius: '10px', backgroundColor: '#fff'}}>
                            <Typography fontFamily={'futura-pt, sans-serif'} variant="h5" component="h1"
                                        color={'#0f2e5d'} textAlign={'center'} gutterBottom>
                                Inserisci i tuoi dati per accedere alla diretta
                            </Typography>
                            <form onSubmit={handleSubmit}>
                                <Grid container gap={2} sx={{mt: 3, width: '100%'}}>
                                    <Grid item xs={12} md={12}>
                                        <CustomTextField
                                            fullWidth
                                            label="Nome"
                                            name="nome"
                                            variant="outlined"
                                            value={formData.nome}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <CustomTextField
                                            fullWidth
                                            label="Cognome"
                                            name="cognome"
                                            variant="outlined"
                                            value={formData.cognome}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <CustomTextField
                                            fullWidth
                                            label="Email"
                                            name="email"
                                            variant="outlined"
                                            value={formData.email}
                                            onChange={handleChange}
                                            type="email"
                                        />
                                    </Grid>
                                </Grid>
                                <Box display={'flex'} width={'100%'} alignItems={'center'}>
                                    { loading ?
                                        <CircularProgress sx={{margin: 'auto', mt: 2, color: '#0f2e5d'}}/>
                                        :
                                        <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{margin: 'auto', mt: 2, backgroundColor: '#0f2e5d'}}
                                        disabled={!isFormComplete}
                                        >
                                            CONFERMA
                                        </Button>
                                    }
                                </Box>
                            </form>
                        </Box>
                    </Container>
                    <img src={'/images/banner.png'} alt={''} className={streamingStyle.banner}/>
                </>

            }
            {access &&
                <>
                    <div className={streamingStyle.videoContainer}>
                        {!alternativePlayer &&
                            <iframe
                                src="https://player.vimeo.com/video/1029593831?h=d8ede5e49c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                frameBorder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                                style={{width: "100%", height: "100%"}} title="BANCA ALETTI">
                            </iframe>
                        }
                        {alternativePlayer &&
                            <iframe
                                src="https://vimeo.com/event/4725526/embed/f1c69096a0"
                                frameBorder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                                style={{width: "100%", height: "100%"}} title="BANCA ALETTI">
                            </iframe>
                        }

                    </div>
                    <div className={streamingStyle.alternativeContainer}>
                        {/*<p style={{marginBottom: "0", textAlign: "right", marginLeft: "auto"}}>
                            Problemi di visualizzazione?
                            Usa il <span style={{textDecoration: 'underline', cursor: 'pointer'}}
                                         onClick={switchPlayer}> Player Alternativo </span>
                        </p>*/}
                    </div>
                    <hr className={streamingStyle.hrLine}/>
                    {/*<div className={streamingStyle.inputContainer}>
                        <div className={streamingStyle.inputHeader}>
                            <img src={qeaIcon} alt={''} className={streamingStyle.qeaIcon}/>
                            <h3 style={{marginLeft: '1%', marginTop: 0, marginBottom: "1%"}}> Fai una domanda.</h3>
                        </div>
                        <div className={streamingStyle.qeaContainer}>
                            {sending &&
                                <div
                                    style={{
                                        position: 'fixed',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor:'rgba(165,201,255,0.54)',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                    <CircularProgress sx={{color: '#0f2e5d'}} />
                                </div>
                            }
                            <TextField
                                onChange={(e) => handleChangeValue(e)}
                                value={question}
                                multiline
                                rows={2}
                                variant="outlined"
                                fullWidth
                                sx={{

                                    '& .MuiOutlinedInput-root': {
                                        // Target the input field container for styling
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)', // Transparent gray background
                                        borderColor: 'white', // For border color
                                        color: 'white',
                                        fontFamily: "futura-pt, sans-serif",
                                        '& fieldset': {
                                            borderColor: 'white', // Change border color
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'white', // Change border color on hover
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'white', // Change border color when focused
                                        },
                                    },
                                }}
                            >
                            </TextField>


                            <Button
                                onClick={postQuestions}
                                onChange={(e) => handleChangeValue(e)}
                                variant="outlined"
                                disabled={question === ''}
                                sx={{
                                    color: 'white', // Colore del testo
                                    borderColor: 'white', // Colore del bordo
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)', // Sfondo grigio trasparente
                                    fontFamily: "futura-pt, sans-serif",
                                    fontSize: "1.5vw",
                                    marginLeft: "1.5vw",
                                    width: "10vw",
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.2)', // Sfondo un po' più scuro al passaggio del mouse
                                        borderColor: 'white', // Mantiene il bordo bianco anche al hover
                                    },
                                    '&.Mui-disabled': {
                                        borderColor: 'rgba(255, 255, 255, 0.3)', // Bordo più chiaro quando il bottone è disabilitato
                                        color: 'rgba(255, 255, 255, 0.3)', // Testo più chiaro quando il bottone è disabilitato
                                    },
                                    '@media (max-width:600px)': {
                                        fontSize: '5vw', // Cambia la dimensione del font per viewport sotto i 600px
                                        width: '40vw', // Cambia la larghezza del bottone per viewport sotto i 600px
                                    },
                                }}
                            >
                                Invia
                            </Button>

                        </div>
                        <p style={{marginTop: "1%"}}>Nei prossimi giorni ricollegati a questo link per rivedere l’evento
                            e ricevere la tua risposta</p>
                    </div>*/}
                </>
            }
            {/*<img src={lineImg} alt={''} className={streamingStyle.topLine}/>*/}


        </div>
    )

}

export default Streaming;